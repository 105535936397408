import * as api from '../api-singleton';
import { Logger } from '../logger';
import * as queries from '../queries/presence';
// import { setPlaythroughState, setPlaythroughConfig } from '../queries';

const logger = new Logger('presence');

/**
 * Gets the list of active sessions for a given playthrough
 * @param {string} playthroughId
 * @returns A list of session objects
 */
export const activeSessionsForPlaythrough = async (playthroughId) => {
  logger.log(`Getting active sessions for playthrough ${playthroughId}`);
  const response = await api.query(queries.activeSessionsForPlaythrough, { playthroughId });
  return response.data.activeSessionsForPlaythrough;
};

/**
 * Gets the current information about a session
 * @param {string} sessionId
 * @returns The session, if any, associated with the passed session id
 */
export const getSession = async (sessionId) => {
  logger.log(`Getting session ${sessionId}`);
  const response = await api.query(queries.getSession, { sessionId });
  return response.data.getSession;
};

/**
 * Marks a player as no longer active in the given session
 * @param {string} sessionId
 * @param {string} playerId
 * @returns undefined
 */
export const leaveSession = async (sessionId, playerId) => {
  logger.log(`Player ${playerId} is leaving session ${sessionId}`);
  const response = await api.query(queries.leaveSession, { sessionId, playerId });
  return response.data.leaveSession;
};

/**
 * Marks a session as no longer active for all players
 * @param {string} sessionId
 * @returns undefined
 */
export const endSession = async (sessionId) => {
  logger.log(`Ending session ${sessionId}`);
  const response = await api.query(queries.endSession, { sessionId });
  return response.data.endSession;
};

/**
 * Subscribes to leave session events for a given session and player
 */
export const onLeaveSession = (sessionId, playerId, callback) => api.subscribe(
  queries.sessionLeft,
  { sessionId, playerId },
  callback,
  (error) => logger.error(error),
);

/**
 * Subscribes to a session being ended
 */
export const onSessionEnded = (sessionId, callback) => api.subscribe(
  queries.sessionEnded,
  { sessionId },
  callback,
  (error) => logger.error(error),
);
