import * as api from '../api-singleton';
import { Logger } from '../logger';
import { getPerson, setAvatar as setAvatarQuery, setSkills as setSkillsQuery } from '../queries';

const logger = new Logger('people');

/**
 * Gets information for a player by id
 *
 * @param {string} personId The person id to query for
 * @returns {Object} An object containing information about the queried person
 */
export const get = async (personId) => {
  logger.log(`Getting person info for ${personId}`);
  const response = await api.query(getPerson, { personId });

  const person = response.data.getPerson;
  if (person.skills !== null) {
    person.skills = JSON.parse(person.skills);
  }
  person.classrooms = response.data.getClassroomsForStudent;

  return response.data.getPerson;
};

/**
 * Sets a person's avatar
 *
 * @param {string} personId The person id to query for
 * @returns {Object} The updated person representation
 */
export const setAvatar = async (personId, avatar) => {
  logger.log(`Setting person ${personId} avatar to ${avatar}`);
  const response = await api.query(setAvatarQuery, { personId, avatar });
  return response.data.setAvatar;
};

/**
 * Sets a person's skills field
 *
 * @param {string} personId The person id to set the field for
 * @param {Object} skills The new value
 * @returns {Promise}
 */
export const setSkills = async (personId, skills) => {
  logger.log(`Setting person ${personId} skills to ${skills}`);
  const response = await api.query(setSkillsQuery, { personId, skills: JSON.stringify(skills) });
  return response.data.setSkills;
};
