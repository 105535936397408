export const setPlaythroughState = `
  mutation UpdatePlaythroughState($playthroughId: ID!, $lastPlayedState: AWSJSON!) {
    setPlaythroughState(playthroughId: $playthroughId, lastPlayedState: $lastPlayedState) {
      playthroughId
    }
  }
`;

export const setPlaythroughConfig = `
  mutation UpdatePlaythroughConfig($playthroughId: ID!, $config: AWSJSON!) {
    setPlaythroughConfig(playthroughId: $playthroughId, config: $config) {
      playthroughId
    }
  }
`;

export const createSession = `
    mutation M($roomCode: String!, $playthroughId: ID!, $playerId: ID) {
      createSession(playthroughId: $playthroughId, roomCode: $roomCode, playerId: $playerId) {
        requestId
        errors
        activeRooms {
          playerId
          roomCode
        }
      }
    }
`;

export const getRequestStatus = `
    query Q($requestId: ID!) {
      getRequestStatus(requestId: $requestId) {
        sessionId
        players
      }
    }
`;

export const setRole = `
  mutation UpdateRole($sessionId: ID!, $playerId: ID!, $newRole: String!) {
    setRole(sessionId: $sessionId, playerId: $playerId, role: $newRole) {
      sessionId
      playerId
      role
    }
  }
`;

export const setGameState = `
  mutation UpdateGameState($sessionId: ID!, $playerId: ID!, $newGameState: AWSJSON!) {
    setGameState(sessionId: $sessionId, playerId: $playerId, gameState: $newGameState) {
      sessionId
      playerId
      gameState
    }
  }
`;

export const setLocalState = `
  mutation UpdateLocalState($sessionId: ID!, $playerId: ID!, $newLocalState: AWSJSON!) {
    setLocalState(sessionId: $sessionId, playerId: $playerId, localState: $newLocalState) {
      sessionId
      playerId
      localState
    }
  }
`;

export const gameStateChange = `
  subscription GameStateChange($sessionId: ID!, $playerId: ID!) {
    gameStateChange(sessionId: $sessionId, playerId: $playerId) {
      playerId
      gameState
    }
  }
`;

export const roleChange = `
  subscription RoleChange($sessionId: ID!, $playerId: ID!) {
    roleChange(sessionId: $sessionId, playerId: $playerId) {
      playerId
      role
    }
  }
`;

export const joinRoom = `
  mutation JoinRoom($roomCode: String!, $playerId: ID!) {
    joinRoom(roomCode: $roomCode, playerId: $playerId) {
      roomCode
    }
  }
`;

export const leaveRoom = `
  mutation LeaveRoom($roomCode: String!, $playerId: ID!) {
    leaveRoom(roomCode: $roomCode, playerId: $playerId) {
      roomCode
    }
  }
`;

export const getRoomStatus = `
  query RoomStatus($roomCode: String!) {
    getRoomStatus(roomCode: $roomCode) {
      playthroughId
      players
    }
  }
`;

export const loadSession = function loadSession(sessionId, players) {
  // TODO: Use GraphQL parameters instead
  const subqueries = [];

  for (let i = 0; i < players.length; i += 1) {
    const playerId = players[i];
    const queryKey = `p${i}`;
    subqueries.push(`
      ${queryKey}:getPlayerSession(sessionId: "${sessionId}", playerId: "${playerId}") {
        gameState
        localState
        role
        lastPlayed
      }
    `);
  }

  return `
  query LoadSession {
    ${subqueries.join('\n')}
  }
  `;
};

export const getPerson = `
query GetPerson($personId: ID!) {
  getPerson(personId: $personId) {
    personId
    name
    avatar
    skills
  }

  getClassroomsForStudent(studentId: $personId) {
    classroomId
    name
    teacherId
    students
  }
}
`;

export const setAvatar = `
mutation SetAvatar($personId: ID!, $avatar: String) {
  setPersonAvatar(personId: $personId, avatar: $avatar) {
    personId
    name
    avatar
  }
}
`;

export const setSkills = `
mutation SetSkills($personId: ID!, $skills: AWSJSON!) {
  setPersonSkills(personId: $personId, skills: $skills) {
    personId
  }
}
`;
