import './polyfills';
import * as api from './api-singleton';

// Sub clients
import * as analytics from './clients/analytics';
import * as auth from './clients/auth';
import * as playthroughs from './clients/playthroughs';
import * as people from './clients/people';
import * as rooms from './clients/rooms';
import * as presence from './clients/presence';

const EventEmitter = require('eventemitter3');
const { validate } = require('./app-sync-config');
const queries = require('./queries');
const { Logger } = require('./logger');

/**
 * Main game client for the DPE, allowing consumers to change session information
 * for a particular player while also subscribing to other players' updates
 */
export class DpeClient extends EventEmitter {
  constructor(appSyncConfig) {
    super();

    validate(appSyncConfig);
    this.appSyncConfig = appSyncConfig;

    api.configure(appSyncConfig);

    this.logger = new Logger('<null> <null>');

    // subclients
    this.analytics = analytics;
    this.auth = auth;
    this.playthroughs = playthroughs;
    this.people = people;
    this.rooms = rooms;
    this.presence = presence;

    /**
     * this.state is a publicly available object that contains the latest known session information.
     * Once a session has been loaded this object will also contain keys for each player in the
     * session. The value under that key is the most recently known data for that player.
     */
    this.state = {
      sessionId: null,
      me: null,
    };

    /**
     * This is the list of existing subscriptions that we've made for changes to our and other
     * players' sessions.
     */
    this.subscriptions = [];
  }

  /**
   * Creates a new session with the provided players
   * @param {string} roomCode The room code
   * @param {string} playthroughId The playthrough id
   * @param {string} playerId The player that's attempting to create a session
   * @returns {string} A request id. Can be used to query for a session id once the session is ready
   */
  async createSession(roomCode, playthroughId, playerId) {
    this.logger.log(`Creating session for room code ${roomCode}, playthrough id ${playthroughId} and player ${playerId}`);
    const response = await api.query(queries.createSession, { roomCode, playthroughId, playerId });

    return response.data.createSession;
  }

  /**
   * Returns the attached session for a request if it exists yet
   * @param {string} requestId
   * @return {Session} An object containing the sessionId and list of players in the session
   */
  async getRequestStatus(requestId) {
    this.logger.log(`Getting request status for request ${requestId}`);
    const response = await api.query(queries.getRequestStatus, { requestId });
    return response.data.getRequestStatus;
  }

  async joinSession(sessionId, me, others) {
    // leave any existing session we were in
    await this.leaveSession();

    this.logger.prefix = `<${sessionId}> <${me}>`;

    this.logger.log('Loading session');
    const players = others.concat([me]);

    // TODO: We should let graphql perform the variable interpolation here, not build a
    // string programmatically
    const query = queries.loadSession(sessionId, players);
    const results = await api.query(query, {});

    this.state.sessionId = sessionId;
    this.state.me = me;
    this.analytics.configureSession(sessionId, me);

    // now that we have player data, we can populate state all the way
    for (let i = 0; i < players.length; i += 1) {
      const playerId = players[i];
      const queryKey = `p${i}`;

      const playerData = results.data[queryKey];
      if (playerData === undefined || playerData === null) {
        this.logger.log(`Skipping loading of player ${playerId}, no data found in API response`);
        this.state[playerId] = {
          role: null,
          gameState: {},
          localState: {},
          lastUpdated: null,
        };
      } else {
        this.logger.log(`Player data found for ${playerId}`);
        this.state[playerId] = {
          role: playerData.role,
          gameState: JSON.parse(playerData.gameState),
          localState: JSON.parse(playerData.localState),
          lastUpdated: playerData.lastPlayed,
        };
      }
    }

    this.logger.log('Session loaded');

    // subscribe to someone ending the session for everyone else
    const sessionEndSubscription = this.presence.onSessionEnded(
      sessionId,
      this.broadcastSessionEnd.bind(this),
    );
    this.subscriptions.push(sessionEndSubscription);

    this.logger.log("Subscribing to other player's game state");

    // For each other player, we subscribe to their changes as well, for each field we care about
    // Note that we don't subscribe to local state, because we don't care about it: The other
    // players' volume settings, etc. don't really matter for this client
    for (const playerId of others) {
      const gameStateSubscription = api.subscribe(
        queries.gameStateChange,
        { sessionId, playerId },
        this.broadcastGameStateChange.bind(this),
        (e) => this.logger.log('Error during subscribe', e),
      );

      const roleChangeSubscription = api.subscribe(
        queries.roleChange,
        { sessionId, playerId },
        this.broadcastRoleChange.bind(this),
        (e) => this.logger.log('Error during subscribe', e),
      );

      const sessionLeftSubscription = this.presence.onLeaveSession(
        sessionId,
        playerId,
        this.broadcastSessionLeave.bind(this),
      );

      this.subscriptions.push(gameStateSubscription);
      this.subscriptions.push(roleChangeSubscription);
      this.subscriptions.push(sessionLeftSubscription);
    }

    // listen for my own role to change too
    const myRoleChangeSubscription = api.subscribe(
      queries.roleChange,
      { sessionId, playerId: me },
      this.broadcastRoleChange.bind(this),
      (e) => this.logger.log('Error during subscribe', e),
    );
    this.subscriptions.push(myRoleChangeSubscription);

    return this.state;
  }

  /**
   * Updates the current player's game state, notifying other players that are listening
   * @param {Object} newGameState The new game state to save
   * @returns {Promise<Object>} A promise that resolves to the new, latest state
   */
  async setGameState(newGameState) {
    this.logger.log('Setting game state', newGameState);
    await api.query(queries.setGameState, {
      sessionId: this.state.sessionId,
      playerId: this.state.me,
      newGameState: JSON.stringify(newGameState),
    });

    this.state[this.state.me].gameState = newGameState;
    this.state[this.state.me].lastUpdated = Date.now();
    this.logger.log('Game state set successfully');
    return this.state;
  }

  /**
   * Persists a new local state for the player to the database
   * @param {Object} newLocalState The new local state to persist
   * @returns {Promise<Object>}
   */
  async setLocalState(newLocalState) {
    this.logger.log('Setting local state');
    await api.query(queries.setLocalState, {
      sessionId: this.state.sessionId,
      playerId: this.state.me,
      newLocalState: JSON.stringify(newLocalState),
    });

    this.state[this.state.me].localState = newLocalState;
    this.state[this.state.me].lastUpdated = Date.now();
    this.logger.log('Local state set successfully');
    return this.state;
  }

  /**
   * Sets the role for a given player in a particular session
   * @param {string} sessionId The session id
   * @param {string} playerId The player to set a role for
   * @param {string} newRole The new role to set
   */
  async setRole(sessionId, playerId, newRole) {
    this.logger.log(`Setting <${sessionId}> <${playerId}> role to ${newRole}`);
    await api.query(queries.setRole, {
      sessionId,
      playerId,
      newRole,
    });
    this.logger.log('Role set successfully');
  }

  /**
   * Event handler for when another player's game state changes
   * @param {Object} result The raw event from our GraphQL subscription
   */
  broadcastGameStateChange(result) {
    const change = result.data.gameStateChange;
    this.logger.log(
      `Received game state update for ${change.playerId}`,
      change.gameState,
    );
    this.state[change.playerId].gameState = JSON.parse(change.gameState);
    this.state[change.playerId].lastUpdated = Date.now();

    this.emit('fieldChange', {
      field: 'gameState',
      value: this.state[change.playerId].gameState,
      player: change.playerId,
    });
  }

  /**
   * Broadcasts when a player's role has been changed
   * @param {Object} result The raw GraphQL event
   */
  broadcastRoleChange(result) {
    const change = result.data.roleChange;
    this.logger.log(
      `Received role change for ${change.playerId}`,
      change.role,
    );
    this.state[change.playerId].role = change.role;
    this.state[change.playerId].lastUpdated = Date.now();

    this.emit('fieldChange', {
      field: 'role',
      value: change.role,
      player: change.playerId,
    });
  }

  /**
   * Event handler for when a session was left in graphql
   * @param {Object} result An object containing the session id and player id of who left
   */
  broadcastSessionLeave(result) {
    this.logger.log(`Session was left ${result}`);
    this.emit('sessionLeft', result);
  }

  /**
   * Event handler for when a session was ended in graphql
   * @param {Object} result An object containing the session id of the session that was ended
   */
  broadcastSessionEnd(result) {
    this.logger.log(`Session was ended ${result}`);
    this.emit('sessionEnded', result);
  }

  async leaveSession() {
    if (this.state.sessionId !== null && this.state.me !== null) {
      await this.presence.leaveSession(this.state.sessionId, this.state.me);
    }

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
    this.state = {
      sessionId: null,
      me: null,
    };
    this.analytics.resetSession();
  }
}
