import * as api from '../api-singleton';
import { Logger } from '../logger';
import { setPlaythroughState, setPlaythroughConfig } from '../queries';

const logger = new Logger('playthroughs');

/**
 * Gets the list of playthroughs for a particular player
 * @param {string} playerId The player id to query for
 * @return {Playthrough[]} An array of playthroughs, each with a playthroughId and list of players
 */
export const forPlayer = async (playerId) => {
  logger.log('Getting player list for playerId', playerId);
  const query = `
  query Q($playerId: ID!) {
    getPlaythroughs(playerId: $playerId) {
      playthroughId
      players
      lastPlayed
      lastPlayedState
      config
    }
  }`;

  const response = await api.query(query, { playerId });
  return response.data.getPlaythroughs.map((p) => {
    // automatically deserialize JSON for lastPlayedState and config if it exists
    const lastPlayedState = p.lastPlayedState !== null ? JSON.parse(p.lastPlayedState) : null;
    const config = p.config !== null ? JSON.parse(p.config) : null;

    return {
      ...p,
      lastPlayedState,
      config,
    };
  });
};

/**
 * Sets the last played state on a playthrough
 */
export const setLastPlayedState = async (playthroughId, state) => {
  logger.log(`Setting playthrough state for ${playthroughId} to ${state}`);
  await api.query(setPlaythroughState, {
    playthroughId,
    lastPlayedState: JSON.stringify(state),
  });
  return state;
};

/**
 * Sets the config on a playthrough
 */
export const setConfig = async (playthroughId, config) => {
  logger.log(`Setting playthrough config for ${playthroughId} to ${config}`);
  await api.query(setPlaythroughConfig, {
    playthroughId,
    config: JSON.stringify(config),
  });
  return config;
};
