export const activeSessionsForPlaythrough = `
query Q($playthroughId: ID!) {
    activeSessionsForPlaythrough(playthroughId: $playthroughId) {
        sessionId
        lastPlayed
    }
}
`;

export const endSession = `
mutation M($sessionId: ID!) {
    endSession(sessionId: $sessionId) {
        sessionId
    }
}
`;

export const leaveSession = `
mutation M($sessionId: ID!, $playerId: ID!) {
    leaveSession(sessionId: $sessionId, playerId: $playerId) {
        sessionId
    }
}
`;

export const getSession = `
query Q($sessionId: ID!) {
    getSession(sessionId: $sessionId) {
        isActive
        lastPlayed
        playthroughId
        roomCode
        players {
            lastPlayed
            playerId
        }
    }
}
`;

export const sessionLeft = `
subscription S($sessionId: ID!, $playerId: ID!) {
    sessionLeft(sessionId: $sessionId, playerId: $playerId) {
        sessionId
        playerId
    }
}
`;

export const sessionEnded = `
subscription S($sessionId: ID!) {
    sessionEnded(sessionId: $sessionId) {
        sessionId
    }
}
`;
