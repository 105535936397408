import * as api from '../api-singleton';
import { Logger } from '../logger';

const logger = new Logger('analytics');

const query = `
mutation M($sessionId: ID!, $playerId: ID!, $eventName: String!, $eventData: AWSJSON!) {
  emitAnalytics(sessionId: $sessionId, playerId: $playerId, eventName: $eventName, eventData: $eventData)
}
`;

let sessionId = null;
let playerId = null;

/**
 * Sets the current session and player id for analytics reporting
 */
export const configureSession = (s, p) => {
  logger.log(`Setting analytics session id to ${s} and player id to ${p}`);
  sessionId = s;
  playerId = p;
};

/**
 * Resets the current session id and player id to null
 */
export const resetSession = () => {
  configureSession(null, null);
};

/**
 * Emits an analytics event
 * @param {string} name The name of the event
 * @param {Object} data The data to associate with the event
 * @return Promise A promise that resolves when the event has been published to the API
 */
export const emit = async (name, data) => {
  logger.log(`Emitting event ${name} with data ${data} for session ${sessionId} and player ${playerId}`);

  // TODO: Do we want this logic here?
  if (sessionId === null || playerId === null) {
    logger.log('No session configured. Not reporting event');
    return undefined;
  }

  const response = await api.query(query, {
    sessionId,
    playerId,
    eventName: name,
    eventData: JSON.stringify(data),
  });

  return response.data.emitAnalytics;
};
