import * as api from '../api-singleton';
import { Logger } from '../logger';

const logger = new Logger('auth');

const LOGIN_QUERY = `
mutation M($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    personId
    token
    error
  }
}
`;

const REFRESH_TOKEN_QUERY = `
mutation M($personId: ID!, $token: String!) {
  refreshToken(personId: $personId, token: $token) {
    personId
    token
    error
  }
}
`;

const TOKEN_STATUS_QUERY = `
query Q($personId: ID!, $token: String!) {
  getTokenStatus(personId: $personId, token: $token) {
    isValid
    secondsRemaining
  }
}
`;

export class AuthenticationException extends Error { }

/**
 * Logs an existing user in
 * @param {username} The username to login with
 * @param {password} The password to use
 */
export const login = async (username, password) => {
  logger.log(`Attempting login for ${username}`);
  const response = await api.query(LOGIN_QUERY, { username, password });
  const person = response.data.login;
  if (person.error !== null && person.error !== undefined) {
    throw new AuthenticationException(person.error);
  }

  return { personId: person.personId, token: person.token };
};

/**
 * Updates the current user's auth token to a fresh one
 */
export const refreshToken = async (personId, token) => {
  logger.log(`Attempting token refresh for ${personId}`);
  const response = await api.query(REFRESH_TOKEN_QUERY, { personId, token });
  const person = response.data.refreshToken;
  if (person.error !== null && person.error !== undefined) {
    throw new AuthenticationException(response.error);
  }

  return { personId: person.personId, token: person.token };
};

/**
 * Gets the current status of an auth token for a person
 */
export const getTokenStatus = async (personId, token) => {
  logger.log(`Getting token status for person ${personId} and token ${token}`);
  const response = await api.query(TOKEN_STATUS_QUERY, { personId, token });
  return response.data.getTokenStatus;
};
