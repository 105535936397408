import * as api from '../api-singleton';
import { Logger } from '../logger';
import { joinRoom, leaveRoom, getRoomStatus } from '../queries';

const logger = new Logger('rooms');

/**
 * Joins a room without an existing playthrough
 * @param {String} roomCode The room to join
 * @param {String} playerId The player to add to the room
 */
export const join = async (roomCode, playerId) => {
  logger.log(`Joining room ${roomCode}`);
  await api.query(joinRoom, { roomCode, playerId });
};

/**
 * Removes a player from a room
 * @param {String} roomCode The room to leave
 * @param {String} playerId The player to remove
 */
export const leave = async (roomCode, playerId) => {
  logger.log(`Removing ${playerId} from ${roomCode}`);
  await api.query(leaveRoom, { roomCode, playerId });
};

/**
 * Gets the current status of a room, returning the room code, list of current players in the
 * room, and the assigned playthrough id if one exists yet
 *
 * @param {String} roomCode The room code to query for
 * @returns {Object} An object containing the roomCode, list of players, and playthrough id
 */
export const status = async (roomCode) => {
  logger.log(`Getting room status for ${roomCode}`);
  const response = await api.query(getRoomStatus, { roomCode });
  return response.data.getRoomStatus;
};
