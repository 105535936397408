import { APIClass, graphqlOperation } from '@aws-amplify/api';

const api = new APIClass(null);

export const configure = (appSyncConfig) => {
  api.configure(appSyncConfig);
};

/**
 * Executes a query
 * @param {string} queryString The GraphQL query to run
 * @param {Object} args The arguments to pass to the query
 * @returns {Object} The response from the API
 */
export const query = (queryString, args) => {
  const operation = graphqlOperation(queryString, args);
  return api.graphql(operation);
};

/**
 * Subscribes to a particular change defined by the query on the backend
 * @param {string} queryString The subscription query to run
 * @param {Object} args The parameters to pass to the query
 * @param {Function} onData The handler for when data is received
 * @param {Function} onError A callback to run if an error occurs
 * @returns {Observable} An Observable instance representing the subscriptions
 * @see https://github.com/zenparsing/zen-observable
 */
export const subscribe = function subscribe(queryString, args, onData, onError) {
  return query(queryString, args).subscribe({
    next: ({ provider, value }) => {
      onData(value, provider);
    },
    error: onError || (() => {}),
  });
};
