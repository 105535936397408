/**
 * A little logger class
 */
export class Logger {
  constructor(prefix) {
    this.prefix = prefix;

    /**
     * Set this field to true disable logging
     */
    this.disabled = false;
  }

  console(level, args) {
    if (this.disabled || Logger.globallyDisabled) {
      return;
    }

    console[level](new Date(), this.prefix, ...args); // eslint-disable-line no-console
  }

  /**
   * Logs the passed arguments, if the logger is enabled
   * @param  {...any} args The values to log out
   * @returns void
   */
  log(...args) {
    this.console('log', args);
  }

  warn(...args) {
    this.console('log', args);
  }

  error(...args) {
    this.console('error', args);
  }
}

/**
 * Set this variable to disable logging globally across all logger instances.
 * Useful for production builds
 */
Logger.globallyDisabled = false;
