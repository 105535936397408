/**
 * A custom class to model AppSync configuration validation issues.
 * The error essentially lets you know what keys are missing.
 */
export class ValidationError extends Error {
  constructor(missingKeys) {
    const message = `The following AppSync config keys were missing ${missingKeys.join(
      ', ',
    )}`;
    super(message);
  }
}

/**
 * Validates an AppSync config object and makes sure all of the required keys are there
 * @param {Object} config The configuration object to validate
 * @returns {Boolean} true if the object is valid
 */
export function validate(config) {
  const expectedKeys = [
    'aws_appsync_graphqlEndpoint',
    'aws_appsync_region',
    'aws_appsync_authenticationType',
    'aws_appsync_apiKey',
  ];

  const missingKeys = [];

  for (const key of expectedKeys) {
    if (config === undefined || config[key] === undefined) {
      missingKeys.push(key);
    }
  }

  if (missingKeys.length > 0) {
    throw new ValidationError(missingKeys);
  }

  return true;
}
